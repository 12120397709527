<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Sensores - Tanques</h4>
            <div class="small text-muted">Adminsitración de todos los tanques disponibles para sensar</div>
          </b-col>
          <b-col sm="5">

            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Cliente">
              <i class="fa fa-plus"></i> Agregar
            </b-button>

            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>

          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="4">
            <b-form-group>
              <b-input-group>
                <b-form-input type="text" size="sm" placeholder="Ingrese su busqueda" v-model="table.filter"></b-form-input>
                <b-input-group-prepend>
                  <b-button variant="dark" size="sm" :disabled="!table.filter" @click="table.filter = ''">
                    <i class="fa fa-filter"></i> Filtrar
                  </b-button>
                </b-input-group-prepend>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-table class="mb-0"
                    responsive
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="true"
                    :items="table.items"
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"                    
                    v-if="table.items.length">

                <template v-slot:table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"                    
                    :key="field.key"
                    :style="{ width: field.width }"
                  >
                </template>

                <template v-slot:cell(id)="data">                  
                  <b>{{ data.item.id }}</b>                  
                </template>

                <template v-slot:cell(image)="data">
                  <b-img  @click="viewImg(data.item)" 
                          :src="data.item.image" 
                          fluid 
                          class="crud-tank-image" 
                          v-if="data.item.image"/>
                  <b-icon icon="image" 
                          v-else 
                          class="h2" 
                          v-b-tooltip.hover
                          title="Imagen No Disponible" />
                </template>

                <template v-slot:cell(name)="data">                  
                  <b>{{data.item.name}}</b><br>
                  {{data.item.description}}         
                </template>
                
                <template v-slot:cell(f_medidas)="data">                                    
                  <b>Diametro: </b> {{data.item.diameter}} cm <br>
                  <b>Alto: </b> {{data.item.height}} cm <br>        
                  <b>Capacidad: </b> {{data.item.capacity}} lt 
                </template>

                <template v-slot:cell(own)="data">                  
                  <b-badge variant="success" v-if="data.item.own">SI</b-badge>                 
                  <b-badge variant="danger" v-else>NO</b-badge>                 
                </template>

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row class="mt-2">
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>
          </b-col>
          <b-col>
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>
        </b-row>

      </b-card>
      
      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
               header-bg-variant="dark"
               header-text-variant="white"
               size="lg">

        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col md="4" sm="12">          
            <b-form-group label="Imagen">
              <Imagen :images="crud.form.image" @load-image="crud.form.image = $event" typeImage="Imagen"/>
            </b-form-group>            
          </b-col> 
          <b-col md="8" sm="12">
            <b-row>
              <b-col md="4" sm="12">
                <b-form-group label="Código">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.code"                               
                                placeholder="Ingresar un código">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="8" sm="12">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.name"
                                required
                                placeholder="Ingresar un nombre">
                  </b-form-input>
                </b-form-group>
              </b-col>          
              <b-col md="12" sm="4">
                <b-form-group label="Descripción">
                  <b-form-textarea
                        id="textarea"
                        v-model="crud.form.description"
                        placeholder="Ingresar una descripción..."
                        rows="3"
                        max-rows="6">
                  </b-form-textarea>              
                </b-form-group>
              </b-col>
            </b-row>  

            <b-row>
              <b-col lg="12">
                <b-tabs card>
                  <b-tab title="Medidas Generales" active>
                    <b-row>
                      <b-col md="4" sm="4">
                        <b-form-group label="Capacidad" description="Medidas en lt">
                          <b-form-input type="number"
                                        size="sm"
                                        v-model="crud.form.capacity"                                  
                                        placeholder="Ingresar capacidad">
                          </b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" sm="4">
                        <b-form-group label="Diametro" description="Medidas en cm">
                          <b-form-input type="number"
                                        size="sm"
                                        v-model="crud.form.diameter"                                  
                                        placeholder="Ingresar diametro">
                          </b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" sm="4">
                        <b-form-group label="Altura" description="Medidas en cm">
                          <b-form-input type="number"
                                        size="sm"
                                        v-model="crud.form.height"                                  
                                        placeholder="Ingresar capacidad">
                          </b-form-input>
                        </b-form-group>
                      </b-col>                                
                    </b-row> 
                  </b-tab>
                  <b-tab title="Medidas Cono Silo">
                    <b-row>
                      <b-col md="4" sm="4">
                        <b-form-group label="Altura" 
                                      description="Altura desde base superior a base inferior en cm">
                          <b-form-input type="number"
                                        size="sm"
                                        step="0.01"
                                        v-model="crud.form.cone_height"                                  
                                        placeholder="Ingresar altura del cono">
                          </b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" sm="4">
                        <b-form-group label="Radio Base Mayor" 
                                      description="Radio de la base mayor en cm">
                          <b-form-input type="number"
                                        size="sm"
                                        step="0.01"
                                        v-model="crud.form.cone_radio_major"                                  
                                        placeholder="Ingresar el radio mayor del cono">
                          </b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" sm="4">
                        <b-form-group label="Radio Base Menor" 
                                      description="Radio de la base menor en cm">
                          <b-form-input type="number"
                                        size="sm"
                                        step="0.01"
                                        v-model="crud.form.cone_radio_minor"                                  
                                        placeholder="Ingresar el radio menor del cono">
                          </b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>                      
                  </b-tab>
                  <b-tab title="Medidas Cilindro">
                    <b-row>
                      <b-col lg="6">
                        <b-form-group label="Longitud (cm)" 
                                      description="Longitud del tanque desde el costado izquierdo hasta el costado derecho">
                          <b-form-input type="number"
                                        size="sm"
                                        step="0.01"
                                        v-model="crud.form.cylinder_longitude"
                                        placeholder="Ingresar longitud del tanque cilíndrico">
                          </b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col lg="6">
                        <b-form-group label="Radio (cm)" 
                                      description="Tomar la medida desde el centro hasta un extremo">
                          <b-form-input type="number"
                                        size="sm"
                                        step="0.01"
                                        v-model="crud.form.cylinder_radio"
                                        placeholder="Ingresar el radio del tanque">
                          </b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>                      
                  </b-tab>                  
                </b-tabs>
              </b-col>
            </b-row>
              
            <b-row class="mt-2">
              <b-col md="12" sm="12">
                <b-form-checkbox v-model="crud.form.own" 
                                 switch 
                                 size="sm"       
                                 :value="1"
                                 :unchecked-value="0">
                  ¿Es un tanque propio?
                </b-form-checkbox>            
              </b-col>                                                  
            </b-row> 
          </b-col>                           
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>
        </div>
        
      </b-modal>
      
      <!-- VIEW IMG -->
      <b-modal v-model="modal.img.active" hide-header hide-footer>            
        <b-img :src="modal.img.content" fluid></b-img>
      </b-modal>      
    </b-col>
  </b-row>
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Imagen from '@/components/inc/image/image' 

  export default {
    components: {
      Imagen,
    },    
    data: () => {
      return {
        primaryColor: '',        
        access: {
          module_id: Modules.SENSORES,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudTank',
          elements: {}
        },        
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class:'align-middle', width:'50px'},
            {key: 'image', label:'Imagen', class:'text-center align-middle', width:'100px'},
            {key: 'code', label: 'Código', class:'text-center align-middle', width:'100px'},
            {key: 'name', label:'Nombre', class: 'w-50 align-middle', width:'200px'},            
            {key: 'f_medidas', label:'Medidas', class: "align-middle", width:'200px'},
            {key: 'own', label:'Propio', class: "text-center align-middle", width:'50px'},
            {key: 'f_action', label:'', class: "align-middle", width:'100px'},
          ],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 50,
          rowSelected: null,
        },      
        crud: {
          form: {
            id: 0,
            code: '',
            name: '',
            description: '',  
            image: [],
            height: 0,
            diameter: 0,
            capacity: 0,
            own: 1,     
            cone_height: 0,
            cone_radio_major: 0,
            cone_radio_minor: 0,
            cylinder_longitude: 0,
            cylinder_radio: 0,
          },                    
        },   
        modal: {
          form: {
            active: false,
            title: ''
          }, 
          img: {
            active: false,            
            content: ''       
          }       
        }                
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },
    mounted () {
      this.cargar()
    },
    methods: {
      cargar () {
        let loader = this.$loading.show();
        var result = serviceAPI.obtenerTanque()

        result.then((response) => {
          var data = response.data
          this.table.items = data
          loader.hide()
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });
      },
      getRowCount (items) {
        return items.length
      },  
      add() {
        this.crud.form.id = 0
        this.crud.form.name = ''
        this.crud.form.code = ''
        this.crud.form.description = ''
        this.crud.form.image = []
        this.crud.form.height = 0
        this.crud.form.diameter = 0
        this.crud.form.capacity = 0
        this.crud.form.own = 1  
        this.crud.form.cone_height = 0
        this.crud.form.cone_radio_major = 0
        this.crud.form.cone_radio_minor = 0
        this.crud.form.cylinder_longitude = 0
        this.crud.form.cylinder_radio = 0

        this.modal.form.title = "Nuevo Tanque"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name          
        this.crud.form.code = item.code
        this.crud.form.description = item.description
        this.crud.form.image = item.image
        this.crud.form.height = item.height
        this.crud.form.diameter = item.diameter
        this.crud.form.capacity = item.capacity
        this.crud.form.own = item.own  
        this.crud.form.cone_height = item.cone_height
        this.crud.form.cone_radio_major = item.cone_radio_major
        this.crud.form.cone_radio_minor = item.cone_radio_minor
        this.crud.form.cylinder_longitude = item.cylinder_longitude
        this.crud.form.cylinder_radio = item.cylinder_radio

        if(item.image) {
          this.crud.form.image = [{ path: item.image }]
        } else {
          this.crud.form.image = []
        }

        this.modal.form.title = "Editar Tanque --> (" + this.crud.form.id + ") " + this.crud.form.name
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Tanque',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: "danger",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.delete()
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {           
        let loader = this.$loading.show();

        var image = new FormData();
        this.crud.form.image.forEach((value, key) => {
          image.append(key, value.path);
        })

        if (this.crud.form.id) {
          var result = serviceAPI.editarTanque(JSON.stringify(this.crud.form), image);
        } else {
          var result = serviceAPI.agregarTanque(JSON.stringify(this.crud.form), image);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.cargar()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })    
      },
      delete() {        
        let loader = this.$loading.show();
        var result = serviceAPI.eliminarTanque(this.crud.form.id);

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.cargar()
          this.$awn.success("Registro eliminado");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })       
      },  
      viewImg(item) {
        this.modal.img.active = true
        this.modal.img.content = item.image
      }
    }  
  }
</script>
<style>
  .crud-tank-image {
    height: 40px;
    cursor: pointer;    
  }
</style>